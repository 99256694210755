import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import React, { useEffect, useState } from "react";

// Put your API key here
builder.init('1db8f7bb109840c9b21fd0dd1758b76b')

function App() {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(undefined);

  // get the page content from Builder
  useEffect( () => {
    async function fetchContent() {
      const content = await builder.get('page', { url: '/kingfisher', entry: '097ebb5be48a4b8d93083688da2338e0' })
      .promise()
      
      setContent(content);
      setNotFound(!content);
    }
    fetchContent();
  }, []);
  // if no page is found, return a 404 page
  if (notFound && !isPreviewingInBuilder) {
    return <div>Not Found 404 Error</div>
  }

  // return the page when found
  return (
    <>
      {/* Render the Builder page */}
      <BuilderComponent model="page" content={content} />
    </>
  );
}

export default App;
